export const environment = {
    production: false,
    staging: true,
    apiDynamic: false,
    api: "https://sandbox.api.jaak.ai",
    apiOneToOne: "https://facade-1to1-http-635t26xtnq-uc.a.run.app",
    urlWeb: "https://sandbox.kyc.jaak.ai/",
    urlDashboard: "https://sandbox.jaak.ai/",
    tayrona: "https://sandbox.api.tayrona.jaak.ai",
    firebaseConfig: {
        apiKey: "AIzaSyCB6LFIE89STEczcCP9JGIMw-uHPkmzjT4",
        authDomain: "jaakrecog-1to1-similarity.firebaseapp.com",
        projectId: "jaakrecog-1to1-similarity",
        storageBucket: "jaakrecog-1to1-similarity.appspot.com",
        messagingSenderId: "19169445718",
        appId: "1:19169445718:web:ef05261dce9b978434b8f7",
        measurementId: "G-SH7GVCW22L",
    },
    blinkKey: "sRwCABNzYW5kYm94Lmt5Yy5qYWFrLmFpBmxleUpEY21WaGRHVmtUMjRpT2pFM01qYzBOakUyTmpJeU1UQXNJa055WldGMFpXUkdiM0lpT2lJd01HWm1NREZsWlMwMk16SmpMVFJpTUdFdFpUZ3laaTAxTlRBMFl6RXpOMlZpWkRNaWZRPT2UjolzqYaxhc3dcQFuyYv67/tMszEQGtd/QAW/6FY11gE0KwHUiIVCM+GvD2DbjP92m73DsexowpE/3Vvu5isN6RhXB+kELovBsFqV1arms80rUnCFGMWzIPErIaw0V42SKLjHHIk1HpSQdhndOXmCWk1eQ==",
};