export const environment = {
    production: false,
    staging: true,
    apiDynamic: false,
    api: "https://sandbox.api.jaak.ai",
    apiOneToOne: "https://facade-1to1-http-635t26xtnq-uc.a.run.app",
    urlWeb: "https://sandbox.kyc.jaak.ai/",
    urlDashboard: "https://sandbox.jaak.ai/",
    tayrona: "https://sandbox.api.tayrona.jaak.ai",
    firebaseConfig: {
        apiKey: "AIzaSyCB6LFIE89STEczcCP9JGIMw-uHPkmzjT4",
        authDomain: "jaakrecog-1to1-similarity.firebaseapp.com",
        projectId: "jaakrecog-1to1-similarity",
        storageBucket: "jaakrecog-1to1-similarity.appspot.com",
        messagingSenderId: "19169445718",
        appId: "1:19169445718:web:ef05261dce9b978434b8f7",
        measurementId: "G-SH7GVCW22L",
    },
    blinkKey: "sRwCABNzYW5kYm94Lmt5Yy5qYWFrLmFpBmxleUpEY21WaGRHVmtUMjRpT2pFM01qVXpOVEEwTVRFME5UWXNJa055WldGMFpXUkdiM0lpT2lJd01HWm1NREZsWlMwMk16SmpMVFJpTUdFdFpUZ3laaTAxTlRBMFl6RXpOMlZpWkRNaWZRPT16tmu8EhE3ftKNpFPP3Bbgv/mxhM+Qr6Xwv3pNeGeUjk4DmlyqSnz+M9/uTK+oDhRYmyRqqfgWq1QrHXm0Olpxqppuvq70QK5IOVo+rPLRYen5e6LPrT8CM6MM6yeB7wHcprPQsOk4Mh7BHRC66QMJZBqQ"
};
